import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../App.css';

import author from '../Images/author.png';

import back_icon from '../Images/back-icon.svg';
import user_icon from '../Images/user-icon.svg';
import code_icon from '../Images/code-icon.svg';
import pencil_icon from '../Images/pencil-icon.svg';
import warning_icon from '../Images/warning-icon.svg';
import check_icon from '../Images/check-icon.svg';
import arrow_icon from '../Images/arrow-icon.svg';
import sparkle_icon from '../Images/sparkle-icon.svg';
import present_icon from '../Images/present-icon.svg';
import diagram_icon from '../Images/diagram-icon.svg';
import book_icon from '../Images/book-icon.svg';

import Footer from './Footer.js';

import mydashboard from '../Images/my-dashboard.jpg';
import mysitemap from '../Images/my-sitemap.jpg';
import apsitemap from '../Images/ap-sitemap.jpg';
import apflowchart from '../Images/ap-flowchart.jpg';
import apdashboard from '../Images/ap-dashboard.jpg';
import apmultistep from '../Images/ap-multistep.jpg';
import apuserzoom from '../Images/ap-userzoom.jpg';
import apscreens from '../Images/ap-screens.jpg';
import apstyleguide from '../Images/ap-styleguide.jpg'
import mybefore from '../Images/my-before.jpg'
import laptop from '../Images/laptop-myresource.jpg'
import myquicklinks from '../Images/my-quick-links.jpg'
import mybanner from '../Images/my-banner.jpg'
import myclaim from '../Images/my-claim.jpg'

class ProjectPortals extends Component {
  
    constructor(props) {
        super();
        this.props = props;
    }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount(){

  }

  visitWebsite() {
    window.location.href = "https://agentportal.icwgroup.com";
  }

  render () {
    
    const footerYear = new Date().getUTCFullYear();

    return (
        
      <div id="project">
        
        <div className="nav">
          <Link to="/" class="link"><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
          <p className="nav-title"><Link to="/" class="link-nav-title">Brandon Falcone</Link></p>
          <Link class="link-disabled" style={{opacity:"0"}} ><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
        </div>
        <img className="project-image" src={laptop} alt=""></img>

        <div className="project-content">
          <p className="project-title-large">Insurance Company Portals: For Policyholders and Agents</p>
          <div className="button-container">
            <p className="project-bold-text">Visit the website:</p>
            <div className="button" onClick={(e) => {this.visitWebsite()}}>
              <p>agentportal.icwgroup.com</p>
              <img className="button-icon" src={arrow_icon} alt=""></img>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">My Tasks</p>

            <div className="icon-bullet">
              <img className="bullet-icon" src={diagram_icon} alt=""></img>
              <div>
                <p className="project-bold-text">UX Design</p>
                <p className="project-subtext">Designed wireframes and mockups for 2 new customer facing portals</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={code_icon} alt=""></img>
              <div>
                <p className="project-bold-text">UI Engineering</p>
                <p className="project-subtext">Programmed the front-end using HTML, CSS, Java, JS, and Vaadin framework</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={present_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Presenting</p>
                <p className="project-subtext">Communicated design ideas to stakeholders, managers, graphic designers, and back-end developers</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={user_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Usability Testing</p>
                <p className="project-subtext">Conducted usability tests with real users to identify issues and iterate the user experience</p>
              </div>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Onboarding New Users</p>
            <p className="project-paragraph">I designed a multi-step form that walks the user through the onboarding process of joining in a way that breaks down a complex process into smaller, manageable pieces without overwhelming them with too much information.</p>
            <img className="project-image-nobordered" src={apmultistep} alt=""></img>
            <p className="project-paragraph">Flowcharts were used to communicate the user flow to stakeholders within the company and served as a good discussion piece.</p>
            <img className="project-image-nobordered" src={apflowchart} alt=""></img>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Sitemap</p>
            <p className="project-paragraph">I designed the information architecture of the website using a sitemap grouping content in the categories Billing, Claims, Free Services, and Account.</p>
            <div>
              <img className="project-image-bordered" src={mysitemap} alt=""></img>
            </div>
            <p className="project-paragraph">The second portal includes more account management requiring this group to be broken down into subcategories, and grouping dedicated to the Administrator audience.</p>
            <div>
              <img className="project-image-bordered" src={apsitemap} alt=""></img>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Quick Links</p>
            <p className="project-paragraph">Policyholders are logging into the portal to pay a bill so I created a quick-link section front and center on the dashboard for easy access to their bills. </p>
            <div>
              <img className="project-image-bordered" src={myquicklinks} alt=""></img>
            </div>
            <p className="project-paragraph">Cards are large and afford clickability due to these signifiers: blue text color, box shadow, arrow icon, hover animation, and pointer cursor. These small but important design elements go a long way in providing users with an enjoyable user experience on the web.</p>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Promotional Content Design</p>
            <p className="project-paragraph">A banner at the top of the dashboard serves to promote new features or blog posts. </p>
            <div>
              <img className="project-image-bordered" src={mybanner} alt=""></img>
            </div>
            <p className="project-paragraph">A clear CTA and content hierarchy emphasizes more important content over less important content. Pagination dots and arrows create an affordance of navigation across a small number of elements. </p>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">UI Style Guide</p>
            <p className="project-paragraph">I developed a style guide using components from the Vaadin component library to maintain a consistent look and feel across websites.</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={sparkle_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Consistent Brand Identity</p>
                <p className="project-subtext">Using an existing brand document, I created the companies first UI style guide</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={book_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Vaadin Components</p>
                <p className="project-subtext">I designed and styled components in the Vaadin component library that I coded in the development phase</p>
              </div>
            </div>
            <img className="project-image-nobordered" src={apstyleguide} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Dashboard Design</p>
            <p className="project-paragraph">A logged in insurance agent see a feed of activity since their last login date. I used the Vaadin dialog component to dive deeper into an activity as an overlay, minimizing the time spent navigating to and from their feed.</p>
            <img className="project-image-nobordered" src={apdashboard} alt=""></img>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Problem</p>
                <p className="project-subtext">It was a challenge making sure the user's activity feed wasn't cluttered with too much data that would cause them to be overwhelmed.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">By identifying the 3 most important pieces of data for each new activity, I created a content hierarchy by varying the size, color, and spacing of text to make the information digestible. </p>
              </div>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">My Deliverables</p>
            <p className="project-paragraph">In my team of developers, graphic designers, and managers, I delivered the following:</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={diagram_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Diagrams</p>
                <p className="project-subtext">From the architecture phase, I delivered sitemaps and flowcharts</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={pencil_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Wireframes and Prototypes</p>
                <p className="project-subtext">In the design phase, I delivered wireframes and interactive prototypes built in Figma</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={code_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Code</p>
                <p className="project-subtext">In the development phase, I programmed the front-end of the website using Java, HTML, CSS, and JS</p>
              </div>
            </div>
          </div>

          

          <div className="icon-bullets">
            <p className="project-text-medium">Designing and Coding</p>
            <p className="project-paragraph">I created a larger, more in depth portal for a large segment of our customer base with more features, more screens, and more collaboration among team members.</p>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">My Role</p>
                <p className="project-subtext">UI/UX Engineer</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Design Tool</p>
                <p className="project-subtext">Figma</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Programming</p>
                <p className="project-subtext">Java, Vaadin, HTML/CSS</p>
              </div>
            </div><img className="project-image-nobordered" src={apscreens} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Usability Testing</p>
            <p className="project-paragraph">In one hour sessions via User Zoom Go, I observed real customers completing tasks using a high-fidelity prototype. I recorded user pain points, prioritized findings, and iterated the designs based on user feedback.</p>
            <img className="project-image-bordered" src={apuserzoom} alt=""></img>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Problem</p>
                <p className="project-subtext">It was revealed that policyholders expect to be able report a claim once they log in, but this functionality exists on a separate website.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">It made sense to provide users access to this feature so I added it as a clickable card on the dashboard that links to a separate website. </p>
              </div>
            </div>
            <img className="project-image-nobordered" src={myclaim} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Before and After</p>
            <p className="project-paragraph">The images below are of the dashboard for policyholders before and after work to redesign and develop the interface for an improved user experience.</p>
            <p className="project-bold-text">Before:</p>
            <img className="project-image-nobordered" src={mybefore} alt=""></img>
            <p className="project-bold-text">After:</p>
            <img className="project-image-nobordered" src={mydashboard} alt=""></img>
          </div>
  
        </div>

        <div className="header-container-repeat">
          <img className="header-profile" src={author} alt=""></img>
          <p className="header-text">Brandon Falcone</p>
          <p className="subheader-text">UX Designer, Developer</p>
        </div>

        <Footer></Footer>
        
      </div>
    );
  }
}

export default ProjectPortals;
