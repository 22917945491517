import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../App.css';

import author from '../Images/author.png';

import back_icon from '../Images/back-icon.svg';
import user_icon from '../Images/user-icon.svg';
import code_icon from '../Images/code-icon.svg';
import pencil_icon from '../Images/pencil-icon.svg';
import warning_icon from '../Images/warning-icon.svg';
import check_icon from '../Images/check-icon.svg';
import arrowdisabled_icon from '../Images/arrowdisabled-icon.svg';
import present_icon from '../Images/present-icon.svg';
import steak_icon from '../Images/steak-icon.svg';
import talk_icon from '../Images/talk-icon.svg';

import Footer from './Footer.js';

import projecticw from '../Images/icw-project.jpg';
import flowcharts from '../Images/icw-flowcharts.jpg';
import userzoom from '../Images/icw-userzoom.jpg';
import nav from '../Images/icw-nav.jpg';
import sod from '../Images/icw-sod.jpg';
import form from '../Images/icw-form.jpg';
import funnel from '../Images/icw-funnel.jpg';
import icw2 from '../Images/icw-Competitive.jpg'; 
import icw3 from '../Images/icw-persona.jpg'; 
import styleguide from '../Images/icw-Style Guide.jpg'; 
import icw6 from '../Images/icw-sitemap.jpg'; 
import golden_ratio from '../Images/icw-golden-ratio.jpg'; 

class ProjectRedesign extends Component {
  
    constructor(props) {
        super();
        this.props = props;
    }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount(){

  }

  visitWebsite() {
    window.location.href = "https://agentportal.icwgroup.com";
  }

  render () {
    
    const footerYear = new Date().getUTCFullYear();

    return (
        
      <div id="project">
        
        <div className="nav">
          <Link to="/" class="link"><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
          <p className="nav-title"><Link to="/" class="link-nav-title">Brandon Falcone</Link></p>
          <Link class="link-disabled" style={{opacity:"0"}} ><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
        </div>
        <img className="project-image" src={projecticw} alt=""></img>

        <div className="project-content">
          <p className="project-title-large">Website Redesign: A Full UX Lifecycle Project</p>
          <div className="button-container">
            <p className="project-bold-text">Visit the website:</p>
            <div className="button-disabled">
              <p>icwgroup.com</p>
              <img className="button-icon" src={arrowdisabled_icon} alt=""></img>
            </div>
            <p className="project-subtext" style={{textAlign: "center", color: "red"}}>This website is currently under construction.</p>

          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">My Tasks</p>

            <div className="icon-bullet">
              <img className="bullet-icon" src={code_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Architecture Design</p>
                <p className="project-subtext">Designed the navigation, information architecture, sitemaps, flowcharts, and user journeys</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={pencil_icon} alt=""></img>
              <div>
              <p className="project-bold-text">Wireframing</p>
                <p className="project-subtext">Designed interactive prototypes in Adobe XD for 100+ pages</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={present_icon} alt=""></img>
              <div>
              <p className="project-bold-text">Collaboration</p>
                <p className="project-subtext">Lead discussions with cross-functional teams to communicate design concepts, gather feedback, and handoff materials</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={user_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Usability Testing</p>
                <p className="project-subtext">Conducted sessions with customers to collect feedback, identify user pain points, and enhance the user experience</p>
              </div>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Competitive Analysis</p>
            <p className="project-paragraph">Competitor websites gave us an understanding of the insurance landscape, industry trends, and best user experiences for creating our own design.</p>
            <img className="project-image-nobordered" src={icw2} alt=""></img>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Persona</p>
            <p className="project-paragraph">Our primary persona is Paul, a policyholder covered with our workers' compensation insurance who typifies the majority of our audience. His profile is filled in below with information we gathered from our research to help guide our design decisions.</p>
            <div>
              <img className="project-image-bordered" src={icw3} alt=""></img>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Information Architecture</p>
            <p className="project-paragraph">The sitemap represents the structure of the 100+ web pages included in the new website. Content was organized in a way to enhance the user experience of our primary persona while providing a grouping of content for our secondary persona. </p>
            <img className="project-image-nobordered" src={icw6} alt=""></img>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Problem</p>
                <p className="project-subtext">It was a challenge designing the website to accommodate 4 new user groups which we originally thought had their own unique workflows for tasks like paying a bill, reporting a claim, getting a quote, and getting appointed. </p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">Creating the sitemap fueled productive discussions with managers and stakeholders that provided clarity about when and where a unique workflow was needed and helped bring cohesiveness to the siloed departments in our company, resulting in simpler user journeys.  </p>
              </div>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Navigation Design</p>
            <p className="project-paragraph">The header gives access to the major tasks our the primary persona. The callout "For Agents & Brokers" provides a space for our secondary persona to consolidate the many links that could otherwise clutter the navigation for our main audience.</p>
            <img className="project-image-nobordered" src={nav} alt=""></img>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Problem</p>
                <p className="project-subtext">It was a challenge creating links for additional content like Careers, About, and Contact which didn't fit under the header links. </p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">I designed a second, smaller header in the top-right corner, referred to as the "eyelash", to place these less frequently accessed pages and minimize clutter for primary audiences.</p>
              </div>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Collaborating with Team Members</p>
            <p className="project-paragraph">We used the Wrike project management application to coordinate deliverables in our team. I collaborated with the following team members below:</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={steak_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Stakeholders</p>
                <p className="project-subtext">I provided stakeholders with sketches, wireframes, and flowcharts</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={talk_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Copywriters</p>
                <p className="project-subtext">I provided copywriters with wireframes in PDF format and received copy in annotations</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={pencil_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Graphic Designers</p>
                <p className="project-subtext">I provided designers with wireframes in Adobe XD and received mockups in PDF</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={code_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Developers</p>
                <p className="project-subtext">I provided developers with functionality documentation and wireframes in Adobe XD and received the final finished website</p>
              </div>
            </div>
          </div>

          

          <div className="icon-bullets">
            <p className="project-text-medium">Flowcharts</p>
            <p className="project-paragraph">Flowcharts helped facilitate productive discussions with stakeholders and become useful for explaining to developers how pages are linked.</p>
            <img className="project-image-bordered" src={flowcharts} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Form Design</p>
            <p className="project-paragraph">A multi-step form walks the user through the journey of onboarding users within their company in a way that breaks a complex flow into smaller, manageable pieces that does not overwhelm the user, and reduces site abandonment.</p>
            <img className="project-image-nobordered" src={form} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Persuasive Design</p>
            <p className="project-paragraph">Using a well placed CTA that emphasizes the "Try for free" feature, I was able to encourage more users to sign up for a service.</p>
            <img className="project-image-bordered" src={sod} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Funneling an Audience</p>
            <p className="project-paragraph">The website aims to funnel prospective customers through a series of steps that converts them to "Find an agent". Product pages with this CTA leverage a familiar layout found on e-commerce websites.</p>
            <img className="project-image-bordered" src={funnel} alt=""></img>
            <img className="project-image-bordered" src={golden_ratio} alt=""></img>
            <p className="project-paragraph">This is also an example of how I incorporated the golden ratio into my design to draw attention to the CTA.</p>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Style Guide</p>
            <p className="project-paragraph">Components throughout the website's wireframes were designed and integrated for a consistent look and feel.</p>
            <img className="project-image-bordered" src={styleguide} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Usability Testing</p>
            <p className="project-paragraph">I recruited 6 local participants that closely matched our 2 personas to take part in 1-hour usability testing sessions at the work environment. Filming and note-taking were captured using UserZoom.</p>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">Participants</p>
                <p className="project-subtext">6</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">User Tasks</p>
                <p className="project-subtext">43</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Prototypes</p>
                <p className="project-subtext">2</p>
              </div>
            </div>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">Observations Recorded</p>
                <p className="project-subtext">100+</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Usability Issues Fixed</p>
                <p className="project-subtext">17</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Satisfaction Rating</p>
                <p className="project-subtext">9.8</p>
              </div>
            </div>
            <img className="project-image-bordered" src={userzoom} alt=""></img>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Problem</p>
                <p className="project-subtext">In my sessions, I discovered that users weren't using the header navigation as often as I liked and struggled to find pages they were looking for. It did not give our primary persona a good experience to bucket all of their links under the nav item "Policyholders".  </p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">I removed the nav item, "Policyholders", and replaced it with "Products", "Billing", and "Claims" which were originally sub-categories. Now we had a more user-friendly nav that tested well with our primary persona, and did not negatively impact our secondary persona.</p>
              </div>
            </div>
          </div>
  
        </div>

        <div className="header-container-repeat">
          <img className="header-profile" src={author} alt=""></img>
          <p className="header-text">Brandon Falcone</p>
          <p className="subheader-text">UX Designer, Developer</p>
        </div>

        <Footer></Footer>
        
      </div>
    );
  }
}

export default ProjectRedesign;
