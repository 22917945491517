import React, { Component } from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Route} from 'react-router-dom';
import './App.css';

import Home from './Components/Home';
import ProjectBajookieland from './Components/Project-Bajookieland.js';
import ProjectAdditiveDrinking from './Components/Project-Additive-Drinking.js';
import ProjectPortals from './Components/Project-Portals.js';
import ProjectRedesign from './Components/Project-Redesign.js';
import ProjectCooperheadArt from './Components/Project-Cooperhead-Art.js';
import ProjectPlazaDelBeero from './Components/Project-Plaza-Del-Beero.js';

class App extends Component {

  render () {
    return (

      <div>
        <BrowserRouter>
          <div id="" className="App">
            <Route path="/" exact component={Home} /> 
            <Route path="/index.html" exact component={Home} />
            <Route path="/portfolio" exact component={Home} />
            <Route path="/about" exact component={Home} />

            <Route path="/additive-drinking" exact component={ProjectAdditiveDrinking} />
            <Route path="/AdditiveDrinking" exact component={ProjectAdditiveDrinking} />
            <Route path="/bajookieland" exact component={ProjectBajookieland} />
            <Route path="/portals" exact component={ProjectPortals} />
            <Route path="/redesign" exact component={ProjectRedesign} />
            <Route path="/cooperhead-art" exact component={ProjectCooperheadArt} />
            <Route path="/plaza-del-beero" exact component={ProjectPlazaDelBeero} />

          </div>
        </BrowserRouter>
      </div>

        

        
    );

  }
}

export default App;