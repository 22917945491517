import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../App.css';

import author from '../Images/author.png';

import tab_all from '../Images/tab-all.svg';
import tab_all_black from '../Images/tab-all-black.svg';
import tab_game from '../Images/tab-game.svg';
import tab_game_black from '../Images/tab-game-black.svg';
import tab_ux from '../Images/tab-ux.svg';
import tab_ux_black from '../Images/tab-ux-black.svg';
import tab_web from '../Images/tab-web.svg';
import tab_web_black from '../Images/tab-web-black.svg';
import lock_icon from '../Images/lock-icon.svg';

import projectad from '../Images/project-ad.jpg';
import projectbl from '../Images/project-bl.jpg';
import projecticw from '../Images/icw-project.jpg';
import cooper from '../Images/cooper-image.jpg';
import pdb from '../Images/pdb.jpg';
import laptop from '../Images/laptop-myresource.jpg'

import Footer from './Footer.js';

class Home extends Component {
  
    constructor(props) {
        super();
        this.props = props;
        this.state = {
          Tab1ClassName: "tab-container-selected",
          Tab2ClassName: "tab-container",
          Tab3ClassName: "tab-container",
          Tab4ClassName: "tab-container",

          Tab1Icon1ClassName: "tab-icon-hidden",
          Tab1Icon2ClassName: "tab-icon-visible",
          Tab2Icon1ClassName: "tab-icon-visible",
          Tab2Icon2ClassName: "tab-icon-hidden",
          Tab3Icon1ClassName: "tab-icon-visible",
          Tab3Icon2ClassName: "tab-icon-hidden",
          Tab4Icon1ClassName: "tab-icon-visible",
          Tab4Icon2ClassName: "tab-icon-hidden",

          AllWorkProjectsStyle: "block",
          GamDevProjectsStyle: "none",
          UXDesignProjectsStyle: "none",
          WebDevProjectsStyle: "none",

          tab1ContainerStyle: {},
          tab2ContainerStyle: {},
          tab3ContainerStyle: {},
          tab4ContainerStyle: {}


        }
    }
  
  componentDidMount() {
    window.scrollTo(0, 0);

    // while (true) {
    //   this.setState({Tab1ClassName: "tab-container-selected"});

    // }
  }

  

  componentWillUnmount(){

  }

  rerouteTo(URL) {
    // const navigate = useNavigate();
    // navigate('/home')
  }

  SelectTab1 = e => {
    this.setState({tab1ContainerStyle: {animation: "projectsAnimation 0.3s ease-in"}});
    this.setState({tab2ContainerStyle: {}});
    this.setState({tab3ContainerStyle: {}});
    this.setState({tab4ContainerStyle: {}});

    this.setState({Tab1ClassName: "tab-container-selected"});
    this.setState({Tab2ClassName: "tab-container"});
    this.setState({Tab3ClassName: "tab-container"});
    this.setState({Tab4ClassName: "tab-container"});
    this.setState({Tab1IconClassName: "tab-icon-selected"});
    this.setState({Tab2IconClassName: "tab-icon"});
    this.setState({Tab3IconClassName: "tab-icon"});
    this.setState({Tab4IconClassName: "tab-icon"});

    this.setState({Tab1Icon2ClassName: "tab-icon-visible"});
    this.setState({Tab1Icon1ClassName: "tab-icon-hidden"});

    this.setState({Tab2Icon1ClassName: "tab-icon-visible"});
    this.setState({Tab2Icon2ClassName: "tab-icon-hidden"});

    this.setState({Tab3Icon1ClassName: "tab-icon-visible"});
    this.setState({Tab3Icon2ClassName: "tab-icon-hidden"});
    
    this.setState({Tab4Icon1ClassName: "tab-icon-visible"});
    this.setState({Tab4Icon2ClassName: "tab-icon-hidden"});

    window.scrollTo(0, 0);

    this.setState({AllWorkProjectsStyle: "block"});
    this.setState({GamDevProjectsStyle: "none"});
    this.setState({UXDesignProjectsStyle: "none"});
    this.setState({WebDevProjectsStyle: "none"});

  }
  SelectTab2 = e => {
    this.setState({tab2ContainerStyle: {animation: "projectsAnimation 0.3s ease-in"}});
    this.setState({tab1ContainerStyle: {}});
    this.setState({tab3ContainerStyle: {}});
    this.setState({tab4ContainerStyle: {}});

    this.setState({Tab1ClassName: "tab-container"});
    this.setState({Tab2ClassName: "tab-container-selected"});
    this.setState({Tab3ClassName: "tab-container"});
    this.setState({Tab4ClassName: "tab-container"});
    
    this.setState({Tab1Icon1ClassName: "tab-icon-visible"});
    this.setState({Tab1Icon2ClassName: "tab-icon-hidden"});

    this.setState({Tab2Icon2ClassName: "tab-icon-visible"});
    this.setState({Tab2Icon1ClassName: "tab-icon-hidden"});

    this.setState({Tab3Icon1ClassName: "tab-icon-visible"});
    this.setState({Tab3Icon2ClassName: "tab-icon-hidden"});

    this.setState({Tab4Icon1ClassName: "tab-icon-visible"});
    this.setState({Tab4Icon2ClassName: "tab-icon-hidden"});

    window.scrollTo(0, 0);

    this.setState({GamDevProjectsStyle: "block"});
    this.setState({AllWorkProjectsStyle: "none"});
    this.setState({UXDesignProjectsStyle: "none"});
    this.setState({WebDevProjectsStyle: "none"});

 
  }
  SelectTab3 = e => {
    this.setState({tab3ContainerStyle: {animation: "projectsAnimation 0.3s ease-in"}});
    this.setState({tab1ContainerStyle: {}});
    this.setState({tab2ContainerStyle: {}});
    this.setState({tab4ContainerStyle: {}});

    this.setState({Tab1ClassName: "tab-container"});
    this.setState({Tab2ClassName: "tab-container"});
    this.setState({Tab3ClassName: "tab-container-selected"});
    this.setState({Tab4ClassName: "tab-container"});
    
    this.setState({Tab1Icon1ClassName: "tab-icon-visible"});
    this.setState({Tab1Icon2ClassName: "tab-icon-hidden"});

    this.setState({Tab2Icon1ClassName: "tab-icon-visible"});
    this.setState({Tab2Icon2ClassName: "tab-icon-hidden"});

    this.setState({Tab3Icon2ClassName: "tab-icon-visible"});
    this.setState({Tab3Icon1ClassName: "tab-icon-hidden"});
    
    this.setState({Tab4Icon1ClassName: "tab-icon-visible"});
    this.setState({Tab4Icon2ClassName: "tab-icon-hidden"});

    window.scrollTo(0, 0);

    this.setState({UXDesignProjectsStyle: "block"});
    this.setState({AllWorkProjectsStyle: "none"});
    this.setState({GamDevProjectsStyle: "none"});
    this.setState({WebDevProjectsStyle: "none"});

  }
  SelectTab4 = e => {
    this.setState({tab4ContainerStyle: {animation: "projectsAnimation 0.3s ease-in"}});
    this.setState({tab1ContainerStyle: {}});
    this.setState({tab2ContainerStyle: {}});
    this.setState({tab3ContainerStyle: {}});

    this.setState({Tab1ClassName: "tab-container"});
    this.setState({Tab2ClassName: "tab-container"});
    this.setState({Tab3ClassName: "tab-container"});
    this.setState({Tab4ClassName: "tab-container-selected"});
    
    this.setState({Tab1Icon1ClassName: "tab-icon-visible"});
    this.setState({Tab1Icon2ClassName: "tab-icon-hidden"});

    this.setState({Tab2Icon1ClassName: "tab-icon-visible"});
    this.setState({Tab2Icon2ClassName: "tab-icon-hidden"});

    this.setState({Tab3Icon1ClassName: "tab-icon-visible"});
    this.setState({Tab3Icon2ClassName: "tab-icon-hidden"});
    
    this.setState({Tab4Icon2ClassName: "tab-icon-visible"});
    this.setState({Tab4Icon1ClassName: "tab-icon-hidden"});

    window.scrollTo(0, 0);

    this.setState({WebDevProjectsStyle: "block"});
    this.setState({AllWorkProjectsStyle: "none"});
    this.setState({GamDevProjectsStyle: "none"});
    this.setState({UXDesignProjectsStyle: "none"});

  }



  

  render () {

    return (
        
      <div id="home">
        <div  className="section-a">

          <div className="header">
            <div className="header-absolute-block">
            </div>
            <div className="header-container">
              <img className="header-profile" src={author} alt=""></img>
              <p className="header-text">Brandon Falcone</p>
              <p className="subheader-text">UX Designer, Developer</p>
            </div>

            <div className="tabs-container">
              <div className={this.state.Tab1ClassName} onClick={this.SelectTab1} >
                <div className="tab-icon-container">
                  <img className={this.state.Tab1Icon1ClassName} src={tab_all} alt=""></img>
                  <img className={this.state.Tab1Icon2ClassName} src={tab_all_black} alt=""></img>
                </div>
                <p className="tab-text">All Work</p>
              </div>
              <div className={this.state.Tab2ClassName} onClick={this.SelectTab2}>
                <div className="tab-icon-container">
                  <img className={this.state.Tab2Icon1ClassName} src={tab_game} alt=""></img>
                  <img className={this.state.Tab2Icon2ClassName} src={tab_game_black} alt=""></img>
                </div>
                <p className="tab-text">Game Dev</p>
              </div>
              <div className={this.state.Tab3ClassName} onClick={this.SelectTab3}>
                <div className="tab-icon-container">
                  <img className={this.state.Tab3Icon1ClassName} src={tab_ux} alt=""></img>
                  <img className={this.state.Tab3Icon2ClassName} src={tab_ux_black} alt=""></img>
                </div>
                <p className="tab-text">UX Design</p>
              </div>
              <div className={this.state.Tab4ClassName} onClick={this.SelectTab4}>
                <div className="tab-icon-container">
                  <img className={this.state.Tab4Icon1ClassName} src={tab_web} alt=""></img>
                  <img className={this.state.Tab4Icon2ClassName} src={tab_web_black} alt=""></img>
                </div>
                <p className="tab-text">Web Dev</p>
              </div>
            </div>
          </div>

          <div className="tab-1-container" style={this.state.tab1ContainerStyle}>
          <div id="all-work-projects" style={{display: this.state.AllWorkProjectsStyle}}>
            <div className="projects-container">
              <Link to="/portals" class="link"><div className="project">
                  <img className="project-image-rounded" src={laptop} alt=""></img>
                  <p className="project-title">Insurance Company Portals</p>
                  <p className="project-subtext">UI design and dev of 2 customer portals</p>
              </div></Link>
              <Link to="/bajookieland" class="link"><div className="project">
                  <img className="project-image-rounded" src={projectbl} alt=""></img>
                  <p className="project-title">Bajookieland Game</p>
                  <p className="project-subtext">iOS game developed in Unity</p>
              </div></Link>
              <Link to="/redesign" class="link"><div className="project">
                  <img className="project-image-rounded" src={projecticw} alt=""></img>
                  <p className="project-title">Website Redesign</p>
                  <p className="project-subtext">Full UX lifecycle process for corporate website</p>
                  </div></Link>
              <Link to="/additive-drinking" class="link"><div className="project">
                  <img className="project-image-rounded" src={projectad} alt=""></img>
                  <p className="project-title">Additive Drinking</p>
                  <p className="project-subtext">Card game, e-commerce site, and Kickstarter</p>
              </div></Link>
              <Link to="/cooperhead-art" class="link"><div className="project">
                  <img className="project-image-rounded" src={cooper} alt=""></img>
                  <p className="project-title">Cooperhead Art Website</p>
                  <p className="project-subtext">Website design and dev for a painter</p>
              </div></Link>
              <Link id="pdb" to="/plaza-del-beero" class="link"><div id="pdb" className="project">
                  <img id="pdb" className="project-image-rounded" src={pdb} alt=""></img>
                  <div id="pdb" className="icon-and-title">
                    <img className="project-lock-icon" src={lock_icon} alt=""></img>
                    <p id="pdb" className="project-title" >Coming Soon...</p>
                  </div>
                  <p id="pdb" className="project-subtext" style={{color: "var(--color-C)"}}>This board game is currently under development</p>
              </div></Link>
            </div>
          </div>
          </div>

          <div className="tab-2-container" style={this.state.tab2ContainerStyle}>
          <div id="game-dev-projects" style={{display: this.state.GamDevProjectsStyle}}>
            <div className="projects-container">
              <Link to="/bajookieland" class="link"><div className="project">
                  <img className="project-image-rounded" src={projectbl} alt=""></img>
                  <p className="project-title">Bajookieland Game</p>
                  <p className="project-subtext">iOS game developed in Unity</p>
              </div></Link>
              <Link to="/additive-drinking" class="link"><div className="project">
                  <img className="project-image-rounded" src={projectad} alt=""></img>
                  <p className="project-title">Additive Drinking</p>
                  <p className="project-subtext">Card game, e-commerce site, and Kickstarter</p>
              </div>
              </Link>
              <Link id="pdb" to="/plaza-del-beero" class="link"><div id="pdb" className="project">
                  <img id="pdb" className="project-image-rounded" src={pdb} alt=""></img>
                  <div id="pdb" className="icon-and-title">
                    <img className="project-lock-icon" src={lock_icon} alt=""></img>
                    <p id="pdb" className="project-title" >Coming Soon...</p>
                  </div>
                  <p id="pdb" className="project-subtext" style={{color: "var(--color-C)"}}>This board game is currently under development</p>
              </div></Link>
            </div>
          </div>
          </div>

          <div className="tab-3-container" style={this.state.tab3ContainerStyle}>
          <div id="ux-design-projects" style={{display: this.state.UXDesignProjectsStyle}}>
            <div className="projects-container">
              <Link to="/redesign" class="link"><div className="project">
                  <img className="project-image-rounded" src={projecticw} alt=""></img>
                  <p className="project-title">Website Redesign</p>
                  <p className="project-subtext">Full UX lifecycle process for corporate website</p>
              </div></Link>
              <Link to="/portals" class="link"><div className="project">
                  <img className="project-image-rounded" src={laptop} alt=""></img>
                  <p className="project-title">Insurance Company Portals</p>
                  <p className="project-subtext">UI design and dev of 2 customer portals</p>
              </div></Link>
              <Link to="/bajookieland" class="link"><div className="project">
                  <img className="project-image-rounded" src={projectbl} alt=""></img>
                  <p className="project-title">Bajookieland Game</p>
                  <p className="project-subtext">iOS game developed in Unity</p>
              </div></Link>
              <Link to="/additive-drinking" class="link"><div className="project">
                  <img className="project-image-rounded" src={projectad} alt=""></img>
                  <p className="project-title">Additive Drinking</p>
                  <p className="project-subtext">Card game, e-commerce site, and Kickstarter</p>
              </div></Link>
            </div>
          </div>
          </div>

          <div className="tab-4-container" style={this.state.tab4ContainerStyle}>
          <div id="web-dev-projects" style={{display: this.state.WebDevProjectsStyle}}>
            <div className="projects-container">
              <Link to="/additive-drinking" class="link"><div className="project">
                  <img className="project-image-rounded" src={projectad} alt=""></img>
                  <p className="project-title">Additive Drinking</p>
                  <p className="project-subtext">Card game, e-commerce site, and Kickstarter</p>
              </div></Link>
              <Link to="/portals" class="link"><div className="project">
                  <img className="project-image-rounded" src={laptop} alt=""></img>
                  <p className="project-title">Insurance Company Portals</p>
                  <p className="project-subtext">UI design and dev of 2 customer portals</p>
              </div></Link>
              <Link to="/cooperhead-art" class="link"><div className="project">
                  <img className="project-image-rounded" src={cooper} alt=""></img>
                  <p className="project-title">Cooperhead Art Website</p>
                  <p className="project-subtext">Website design and dev for a painter</p>
              </div></Link>
            </div>
          </div>
          </div>

        </div>

        <Footer></Footer>


      </div>
    );
  }
}

export default Home;
