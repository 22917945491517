import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../App.css';

import bee from '../Images/bee-icon.svg';

class Footer extends Component {
  
    constructor(props) {
        super();
        this.props = props;
    }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount(){

  }

  rerouteTo(URL) {
    // const navigate = useNavigate();
    // navigate('/home')
  }

  render () {
    
    const footerYear = new Date().getUTCFullYear();

    return (
        
      <div id="footer">


        <div className="section-b-container">
          <div className="section-b">
            {/* <div className="bee-icon-container">
              <img className="bee-icon" src={bee} alt=""></img>
              <p className="bee-text">Thanks for reading!</p>
            </div> */}

            <div className="footer-container">
              <p className="project-text-medium">About Me</p>
              <p className="footer-paragraph">Hey! I’m on a mission to elevate user experiences through empathy, creativity, and innovation. With a degree in computer science focusing on human-computer interaction, I’ve honed my skills in bridging the gap between design and development. My background in IT allows me to approach challenges with enthusiasm, time management, and organizational skills that encourage collaboration.</p>
              <p className="footer-paragraph">I have an eye for usability, a passion for designing the best user experience possible, and the ability to solve problems and think creatively. I excel in translating user needs into intuitive and enjoyable experiences, collaborating with team members to refine designs and identify usability issues. I thrive in fast-paced Agile environments and am always eager to work with like-minded, motivated team members to create digital products that users will love.</p>
              <p className="footer-paragraph">Let’s work together to design digital products that elevate user experiences and bring ideas to life!</p>
              <div className="footer-contact-container">
                <p className="footer-label">Contact:</p>
                <a className="footer-value" href="mailto:brandonfalcone@gmail.com">brandonfalcone@gmail.com</a>
              </div>

              <div className="footer-sitemap-container">
                <p className="footer-label">Sitemap</p>
                <div className="footer-columns">
                  <div className="footer-column">
                    <Link to="/" class="link-decorated"><p className="footer-subtext">Home</p></Link>
                    <Link to="/bajookieland" class="link-decorated"><p className="footer-subtext">Bajookieland</p></Link>
                    <Link to="/portals" class="link-decorated"><p className="footer-subtext">ICW Group Portals</p></Link>
                  </div>
                  <div className="footer-column">
                    <Link to="/additive-drinking" class="link-decorated"><p className="footer-subtext">Additive Drinking</p></Link>
                    <Link to="/redesign" class="link-decorated"><p className="footer-subtext">Website Redesign</p></Link>
                    <Link to="/cooperhead-art" class="link-decorated"><p className="footer-subtext">Cooperhead Art</p></Link>
                  </div>
                </div>
              </div>
              
              <p className="footer-copyright">© {footerYear} Brandon Falcone</p>

            </div>
          </div>
        </div>




          
          
          

        
      </div>
    );
  }
}

export default Footer;
