import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../App.css';

import author from '../Images/author.png';

import back_icon from '../Images/back-icon.svg';
import apple_icon from '../Images/apple-icon.svg';
import user_icon from '../Images/user-icon.svg';
import code_icon from '../Images/code-icon.svg';
import pencil_icon from '../Images/pencil-icon.svg';
import map_icon from '../Images/map-icon.svg';
import brain_icon from '../Images/brain-icon.svg';
import steps_icon from '../Images/steps-icon.svg';
import puzzle_icon from '../Images/puzzle-icon.svg';
import warning_icon from '../Images/warning-icon.svg';
import check_icon from '../Images/check-icon.svg';
import hammer_icon from '../Images/hammer-icon.svg';
import gun_icon from '../Images/gun-icon.svg';
import bomb_icon from '../Images/bomb-icon.svg';
import teleporter_icon from '../Images/teleporter-icon.svg';

import Footer from './Footer.js';

import projectbl from '../Images/project-bl.jpg';
import sprites from '../Images/bl-sprites.jpg';
import marketing from '../Images/bajookieland-marketing.jpg';
import unity from '../Images/bl-unity.jpg';
import screenflow from '../Images/bl-screenflow.jpg';
import sketch from '../Images/bl-sketch.jpg';
import pause from '../Images/bajookieland-pause.jpg';
import purchase from '../Images/bajookieland-purchase.jpg';
import bomb from '../Images/bajookieland-bomb.jpg';
import vid from '../Images/bajookieland-vid.mp4'; 
import style_guide from '../Images/bajookieland-style-guide.jpg'; 

class ProjectBajookieland extends Component {
  
    constructor(props) {
        super();
        this.props = props;
    }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount(){

  }

  visitWebsite() {
    window.location.href = "https://apps.apple.com/us/app/bajookieland/id6479661549";
  }

  render () {
    
    const footerYear = new Date().getUTCFullYear();

    return (
        
      <div id="project">
        
        <div className="nav">
          <Link to="/" class="link"><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
          <p className="nav-title"><Link to="/" class="link-nav-title">Brandon Falcone</Link></p>
          <Link class="link-disabled" style={{opacity:"0"}} ><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
        </div>
        <img className="project-image" src={projectbl} alt=""></img>

        <div className="project-content">
          <p className="project-title-large">Bajookieland: A Puzzle Platforming Game For iOS</p>
          <div className="button-container">
            <p className="project-bold-text">Play the game:</p>
            <div className="button" onClick={(e) => {this.visitWebsite()}}>
              <img className="button-icon" src={apple_icon} alt=""></img>
              <p>Download on the App Store</p>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">My Tasks</p>

            <div className="icon-bullet">
              <img className="bullet-icon" src={user_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Video Game UX Design</p>
                <p className="project-subtext">Designed the tutorial, purchasing flow, and screen architecture.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={code_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Programming</p>
                <p className="project-subtext">Developed the game in C# and Unity for iOS.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={pencil_icon} alt=""></img>
              <div>
                <p className="project-bold-text">UI and Sprite Artwork</p>
                <p className="project-subtext">Created all sprite image assets and user interface components.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={map_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Level Design</p>
                <p className="project-subtext">Constructed aesthetic levels with progressive difficulty.</p>
              </div>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Gameplay Video</p>

            <div className="mobileVideoContainer">
              <video className="mobileVideo" muted="true" autoplay="true" loop="true" playsInline="true" webkit-playsinline>
                  <source src={vid} type="video/mp4" />
-               </video>
              </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Unity Development</p>
            <p className="project-paragraph">I created the game in Unity, exported the project to XCode, set up an Apple Developer Account, tested on Test Flight, and launched the game on the App Store.</p>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">Programming Language</p>
                <p className="project-subtext">C#</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Development Platform</p>
                <p className="project-subtext">Unity</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Number of Scripts</p>
                <p className="project-subtext">50</p>
              </div>
            </div>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">In-App Purchase</p>
                <p className="project-subtext">Yes</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Consoles</p>
                <p className="project-subtext">iPhone & iPad</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Other Tools Used</p>
                <p className="project-subtext">UI Toolkit</p>
              </div>
            </div>
            <div>
              <img className="project-image-nobordered" src={unity} alt=""></img>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Level Design</p>

            <div className="icon-bullet">
              <img className="bullet-icon" src={brain_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Tutorial Integration</p>
                <p className="project-subtext">The tutorial is blended seamlessly into the gameplay allowing the player to learn basic controls while keeping them immersed</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={steps_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Gradual Introduction</p>
                <p className="project-subtext">New gameplay mechanics were systematically introduced, gradually increasing the complexity of levels</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={puzzle_icon} alt=""></img>
              <div>
                <p className="project-bold-text">16 Puzzling Levels</p>
                <p className="project-subtext">From sketches to code, 16 levels were crafted to challenge the players' wits and delight the eye</p>
              </div>
            </div>
            <img className="project-image-nobordered" src={sketch} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Tutorial Design</p>
            <p className="project-paragraph">Play testing revealed that an additional tooltip was needed to help the player understand how to detonate bombs.</p>
            <img className="project-image-nobordered" src={bomb} alt=""></img>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Problem</p>
                <p className="project-subtext">The player was confused about how to use the bomb weapon that required an additional touch than what they were used to with the hammer and gun.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">I used the familiar tutorial tooltip that appears when the player uses the bomb for the first time to illustrate that a second touch was required to detonate the bomb. </p>
              </div>
            </div>

          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Screen Architecture</p>
            <p className="project-paragraph">I used a flowchart to illustrate how screens are connected, what options are available on each screen, and the players' purchasing flow.</p>
            <img className="project-image-bordered" src={screenflow} alt=""></img>
            <p className="project-paragraph">Large yellow buttons navigate the player to their next level and keep track of the players' progress eventually funneling them to the Levels screen to make an In-App Purchase, unlocking the full game.</p>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">UI Style Guide</p>
            <p className="project-paragraph">I created a UI that makes it most accessible on mobile devices in portrait mode. I used Figma to create all of the UI assets and implemented the code in Unity's UI Toolkit.</p>
            <img className="project-image-nobordered" src={style_guide} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Purchasing More Levels</p>
            <p className="project-paragraph">Players are funneled to the Level Select screen where they can see a scrolling list of all levels in the game and make an In-App Purchase to unlock all locked levels.</p>
            <p className="project-paragraph">I designed a large CTA using blue color that matches the blue lock icon that appears next to each locked level. Clicking the button launches the iOS StoreKit UI.</p>
            <img className="project-image-nobordered" src={purchase} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">In-Game Weapons</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={hammer_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Hammer</p>
                <p className="project-subtext">The hammer is a strong weapon that can break boxes, destroy walls, and push enemies that block your path.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={gun_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Gun</p>
                <p className="project-subtext">The gun has a long range to trigger explosions, but is weak against enemies' hard metal exterior</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={bomb_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Bomb</p>
                <p className="project-subtext">The bomb is a versatile weapon for creating explosions and large enough to create cover to hide behind</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={teleporter_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Teleporter</p>
                <p className="project-subtext">The teleporter has a long range for warping past obstacles and evading enemy detection</p>
              </div>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Sprite Artwork</p>
            <p className="project-paragraph">I dedicated minimal time to create the sprite artwork for the game and Figma made it possible to quickly create each sprite and visualize everything on a single page.</p>
            <div className="trio-container">
              <div className="trio-item">
                <p className="project-bold-text">Tools Used</p>
                <p className="project-subtext">Figma</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Sprite Count</p>
                <p className="project-subtext">320</p>
              </div>
              <div className="trio-item">
                <p className="project-bold-text">Art Style</p>
                <p className="project-subtext">Cute Cartoon</p>
              </div>
            </div>            
            <img className="project-image-nobordered" src={sprites} alt=""></img>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Pause Screen</p>
            <p className="project-paragraph">An overlay appears on the screen when the pause button is clicked that allows control over the audio and options to resume play, restart the level, skip a level, and return to the main menu.</p>
            <img className="project-image-nobordered" src={pause} alt=""></img>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Problem</p>
                <p className="project-subtext">It was a challenge conveying all of these options on a single screen while maintaining a consistent UI across the app.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">After identifying the primary, secondary, and tertiary actions the player can take, it was easy to follow the UI style guide to assign color, positioning, and sizing of buttons.</p>
              </div>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Funneling the Player to Purchase the Full Game</p>
            <div className="icon-bullet">
              <img className="bullet-icon" src={warning_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Problem</p>
                <p className="project-subtext">I needed players to easily experience the full fun of the game while funneling them to purchase the full game, unlocking all levels.</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={check_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Solution</p>
                <p className="project-subtext">The player is able to easily complete the first 4 levels one after another, but on the 5th level the player is re-routed to the Level Select screen where they are able to understand that the Purchase button will allow them to unlock the remaining levels. </p>
              </div>
            </div>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Marketing Images</p>
            <p className="project-paragraph">I created images for prospective players to preview the game on the App Store.</p>            
            <img className="project-image-nobordered" src={marketing} alt=""></img>
          </div>
  
        </div>

        <div className="header-container-repeat">
          <img className="header-profile" src={author} alt=""></img>
          <p className="header-text">Brandon Falcone</p>
          <p className="subheader-text">UX Designer, Developer</p>
        </div>

        

        <Footer></Footer>

      </div>
    );
  }
}

export default ProjectBajookieland;
