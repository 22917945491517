import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../App.css';

import author from '../Images/author.png';

import cooper from '../Images/cooper-image.jpg';
import cooper_1 from '../Images/cooper-1.jpg';
import cooper_2 from '../Images/cooper-2.jpg';
import cooper_3 from '../Images/cooper-3.jpg';
import cooper_4 from '../Images/cooper-4.jpg';
import cooper_5 from '../Images/cooper-5.jpg';

import back_icon from '../Images/back-icon.svg';
import code_icon from '../Images/code-icon.svg';
import pencil_icon from '../Images/pencil-icon.svg';
import arrow_icon from '../Images/arrow-icon.svg';
import bucket_icon from '../Images/bucket-icon.svg';

import Footer from './Footer.js';

class ProjectRedesign extends Component {
  
    constructor(props) {
        super();
        this.props = props;
    }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount(){

  }

  visitWebsite() {
    window.location.href = "https://cooperheadart.com";
  }

  render () {
    
    const footerYear = new Date().getUTCFullYear();

    return (
        
      <div id="project">
        
        <div className="nav">
          <Link to="/" class="link"><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
          <p className="nav-title"><Link to="/" class="link-nav-title">Brandon Falcone</Link></p>
          <Link class="link-disabled" style={{opacity:"0"}} ><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
        </div>
        <img className="project-image" src={cooper} alt=""></img>

        <div className="project-content">
          <p className="project-title-large">Cooperhead Art: Design and Dev of a Painter's Website</p>
          <div className="button-container">
            <p className="project-bold-text">Visit the website:</p>
            <div className="button" onClick={(e) => {this.visitWebsite()}}>
              <p>CooperheadArt.com</p>
              <img className="button-icon" src={arrow_icon} alt=""></img>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">My Tasks</p>

            <div className="icon-bullet">
              <img className="bullet-icon" src={pencil_icon} alt=""></img>
              <div>
                <p className="project-bold-text">Web Design</p>
                <p className="project-subtext">Designed sketches and mockups for client feedback</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={code_icon} alt=""></img>
              <div>
              <p className="project-bold-text">Web Development</p>
                <p className="project-subtext">Programmed the website in React, HTML, CSS, JS</p>
              </div>
            </div>
            <div className="icon-bullet">
              <img className="bullet-icon" src={bucket_icon} alt=""></img>
              <div>
              <p className="project-bold-text">AWS S3 Bucket</p>
                <p className="project-subtext">Created a content management system for the client</p>
              </div>
            </div>
          </div>

          <div className="icon-bullets">
            <p className="project-text-medium">Website</p>
            <p className="project-paragraph">The website serves to promote the painter's artwork and share his life experience.</p>
            <img className="project-image-nobordered" src={cooper_1} alt=""></img>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Content Management</p>
            <p className="project-paragraph">The client wanted a way to change images on the website from time to time. I set up an AWS S3 Bucket where the client can easily drag and drop new images.</p>
            <img className="project-image-bordered" src={cooper_2} alt=""></img>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Sketches</p>
            <p className="project-paragraph">I designed the webpages with this clipboard and grid paper to brainstorm the navigation, page layout, and color.</p>
            <img className="project-image-nobordered" src={cooper_3} alt=""></img>
            <img className="project-image-nobordered" src={cooper_4} alt=""></img>
          </div>

          <div className="project-chunk">
            <p className="project-text-medium">Client Communication</p>
            <p className="project-paragraph">We communicated over text as the client liked for quick turn-arounds and getting things done. </p>
            <img className="project-image-nobordered" src={cooper_5} alt=""></img>
          </div>

  
        </div>

        <div className="header-container-repeat">
          <img className="header-profile" src={author} alt=""></img>
          <p className="header-text">Brandon Falcone</p>
          <p className="subheader-text">UX Designer, Developer</p>
        </div>

        <Footer></Footer>
        
      </div>
    );
  }
}

export default ProjectRedesign;
