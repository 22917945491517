import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import '../App.css';

import author from '../Images/author.png';

import back_icon from '../Images/back-icon.svg';
import user_icon from '../Images/user-icon.svg';
import code_icon from '../Images/code-icon.svg';
import pencil_icon from '../Images/pencil-icon.svg';
import arrow_icon from '../Images/arrow-icon.svg';
import cards_icon from '../Images/cards-icon.svg';
import shopping_icon from '../Images/shopping-icon.svg';
import qr_icon from '../Images/qr-icon.svg';
import sparkle_icon from '../Images/sparkle-icon.svg';

import Footer from './Footer.js';

import projectad from '../Images/project-ad.jpg';
import ad1 from '../Images/ad1.jpg';
import ad2 from '../Images/ad2.jpg';
import ad3 from '../Images/ad3.jpg';
import ad4 from '../Images/ad4.jpg';
import ad6 from '../Images/ad6.jpg';
import ad11 from '../Images/ad11.jpg';
import ad15 from '../Images/ad15.jpg';
import ad16 from '../Images/ad16.jpg';
import pdb from '../Images/pdb.jpg';


class ProjectPlazaDelBeero extends Component {
  
    constructor(props) {
        super();
        this.props = props;
    }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount(){

  }

  visitWebsite() {
    window.location.href = "https://additivedrinking.com";
  }

  render () {
    
    const footerYear = new Date().getUTCFullYear();

    return (
        
      <div id="project">
        
        <div className="nav">
          <Link to="/" class="link"><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
          <p className="nav-title"><Link to="/" class="link-nav-title">Brandon Falcone</Link></p>
          <Link class="link-disabled" style={{opacity:"0"}} ><div className="back-container">
            <img className="back-icon" src={back_icon} alt=""></img>
            <p className="back-text">Back</p>
          </div></Link>
        </div>
        <img className="project-image" src={pdb} alt=""></img>

        <div className="project-content">
          <p className="project-title-large">Coming Soon: Plaza Del Beero!</p>

          <div className="project-chunk">
            <p className="project-paragraph">This board game is currently under development. Please check back later.</p>
          </div>

          <div className="project-chunk">
          </div>
          
  
        </div>

        <div className="header-container-repeat">
          <img className="header-profile" src={author} alt=""></img>
          <p className="header-text">Brandon Falcone</p>
          <p className="subheader-text">UX Designer, Developer</p>
        </div>

        <Footer></Footer>
        
      </div>
    );
  }
}

export default ProjectPlazaDelBeero;
